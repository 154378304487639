import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Radio from '@material-ui/core/Radio';
import ListItemText from '@material-ui/core/ListItemText';

interface Props {
    value: any;
    checked: boolean;
    primaryText?: string;
    secondaryText?: string;
    onChange: (value: string) => void;
}

export const SelectorListOptionItem: React.FC<Props> = ({checked, onChange, primaryText, secondaryText, value}) => {
    const handleClick = (_ev: any) => {
        if (!onChange) return;
        onChange(value);
    };
    const handleChange = (event: any) => {
        if (!onChange) return;
        onChange(event.target.value);
    };
    return (
        <ListItem button onClick={handleClick}>
            <ListItemIcon>
                <Radio value={value} checked={checked} onChange={handleChange} />
            </ListItemIcon>
            <ListItemText primary={primaryText} secondary={secondaryText} />
        </ListItem>
    );
};
