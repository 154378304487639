import React from 'react';
import classNames from 'classnames';
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {ProductImageThumb} from '../../product/components/ProductImageThumb';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {BorderRadius} from '../../../theme';
import {computeProductSpecificName} from '../../core/util/ProductSpecificUtil';
import {ProductSpecificPriceLabel} from '../../product/components/ProductSpecificPriceLabel';
import {CartItem} from '../../core/models/Cart';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        thumb: {
            borderRadius: BorderRadius.SM,
            border: `1px solid ${theme.palette.grey[300]}`,
        },
        hrow: {
            display: 'flex',
            flexDirection: 'row',
            paddingTop: theme.spacing(0.5),
            paddingBottom: theme.spacing(0.5),
        },
        middle: {
            flex: 1,
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
        text: {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            fontWeight: 'bold',
        },
    }),
);

interface Props {
    item: CartItem;
}

export const CheckoutProductItem: React.FC<Props> = ({item}) => {
    const classes = useStyles();
    const productSpecific = item.productSpecific;
    const prodName = computeProductSpecificName(productSpecific);
    const {product} = productSpecific;
    const mainImage = product && product.mainImage ? product.mainImage : undefined;
    return (
        <Box className={classes.hrow}>
            <Badge badgeContent={item.quantity} color={'primary'}>
                {mainImage && (
                    <ProductImageThumb
                        style={{width: 65, height: 65}}
                        imageClassName={classes.thumb}
                        image={mainImage}
                        selected={true}
                    />
                )}
            </Badge>
            <Typography className={classNames(classes.middle, classes.text)} variant="subtitle1">
                {prodName}
            </Typography>
            <Typography className={classes.text} variant="subtitle1">
                <ProductSpecificPriceLabel product={productSpecific} />
            </Typography>
        </Box>
    );
};
