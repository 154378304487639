import React, {useEffect} from 'react';
import SEO from '../modules/layout/containers/SEO';
import {Layout} from '../modules/layout/containers/Layout';
import {CheckoutPageContent} from '../modules/checkout/containers/CheckoutPageContent';
import {CartRemote} from '../modules/core/models/CartRemote';
import {useProductCatalogThumbS} from '../modules/common/hooks/useProductCatalogThumbS';
import {mapCartRemoteToCartWithImages} from '../modules/core/mapper/CartMapper';
import {useSiteMetadata} from '../modules/common/hooks/useSiteMetadata';
import {computeSEOMeta} from '../modules/core/util/SEOUtil';
import {useFetchCartCustomerPayments} from '../modules/common/hooks/useFetchCartCustomerPayments';
import {useCustomerMutationId} from '../modules/common/hooks/useCustomerMutationId';
import {CheckoutInput} from '../modules/core/models/mutations/CheckoutInput';
import {useFetchCheckout} from '../modules/common/hooks/useFetchCheckout';
import {navigate} from 'gatsby';
import get from 'lodash/get';
import {useCheckoutPageStaticData} from '../modules/common/hooks/useCheckoutPageStaticData';

interface Props {
    location: Location;
}

export const Checkout: React.FC<Props> = ({location}) => {
    const data = useCheckoutPageStaticData();
    const pagesNodes: any = get(data, 'wpgraphql.pages.nodes');
    const pageData = pagesNodes && pagesNodes.length > 0 ? pagesNodes[0] : null;

    const {loading, cart, customer, paymentGateways} = useFetchCartCustomerPayments();
    const mutationId = useCustomerMutationId();
    const cartRemote: CartRemote = cart;

    console.log('cartRemote:', cartRemote);

    const prodCatalog = useProductCatalogThumbS();
    const cartWithImages = mapCartRemoteToCartWithImages(mutationId, cartRemote, prodCatalog);

    const extractPaymentNodes = (edgesWrapper: any) =>
        edgesWrapper && edgesWrapper.edges && edgesWrapper.edges.map((edge: any) => edge.node);

    const onCheckoutCompleted = (_data: any) => {
        console.log('Checkout has been completed...:', _data);
        navigate('/thankyou');
    };

    const {checkout, checkoutLoading} = useFetchCheckout({onCompleted: onCheckoutCompleted});

    const handleCheckout = (input: CheckoutInput) => {
        checkout({variables: {input}});
    };
    useEffect(() => {
        console.log('Redner checkout page...', cartRemote);
    }, [cartRemote]);

    const siteMetadata = useSiteMetadata();
    const {title, metadata} = computeSEOMeta(siteMetadata, pageData && pageData.seo ? pageData.seo : ({} as any));
    return (
        <Layout location={location} contentStyle={{marginTop: 0, marginBottom: 0}}>
            <SEO title={title} meta={metadata} />
            <CheckoutPageContent
                cart={cartWithImages}
                customer={customer}
                loading={loading || checkoutLoading}
                paymentGateways={extractPaymentNodes(paymentGateways)}
                onCheckout={handleCheckout}
            />
        </Layout>
    );
};

export default Checkout;
