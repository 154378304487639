import gql from 'graphql-tag';

export const OrderCoreInfoFragmentCS = gql`
    fragment OrderCoreInfoFragmentCS on Order {
        cartHash
        currency
        id
        modified
        needsPayment
        needsProcessing
        needsShippingAddress
        orderId
        orderKey
        orderNumber
        orderVersion
        status
    }
`;
