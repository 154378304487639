import React from 'react';
import Checkbox, {CheckboxProps} from '@material-ui/core/Checkbox';

interface Props extends CheckboxProps {
    value: any;
    onChange: (e: any) => void;
}

export const FormalCheckbox: React.FC<Props> = ({value, onChange, ...other}) => {
    const handleChange = (_e: any, checked: boolean) => {
        const mockEvent = {target: {value: checked}};
        if (!onChange) return;
        onChange(mockEvent as any);
    };
    const checked = value === true;
    return <Checkbox checked={checked} onChange={handleChange} {...other} />;
};
