import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {createStyles, makeStyles} from '@material-ui/core/styles';
import {Cart} from '../../core/models/Cart';
import {DividerPadded} from '../../uxui/DividerPadded';
import {computeCartSubTotal} from '../../core/util/CartUtil';
import {CheckoutBillingDescriptor} from './CheckoutBillingDescriptor';

const useStyles = makeStyles(() =>
    createStyles({
        hRow: {
            display: 'flex',
            flexDirection: 'row',
        },
        flex: {
            flex: 1,
        },
    }),
);

interface Props {
    cart: Cart;
}

export const CheckoutSummary: React.FC<Props> = ({cart}) => {
    const classes = useStyles();
    const subtotal = computeCartSubTotal(cart);
    return (
        <Box>
            <Box className={classes.hRow}>
                <Typography variant="h6" className={classes.flex}>
                    Subtotal
                </Typography>
                <Typography variant="h6">{subtotal}</Typography>
            </Box>
            <Box className={classes.hRow}>
                <Typography variant="h6" className={classes.flex}>
                    Shipping
                </Typography>
                <Typography variant="body1">{cart.shippingTotal}</Typography>
            </Box>
            <DividerPadded variant="fullWidth" />
            <Box className={classes.hRow}>
                <Typography variant="h6" className={classes.flex}>
                    Total
                </Typography>
                <Box mr={1}>
                    <Typography variant="h6">USD</Typography>
                </Box>
                <Typography variant="h5">{subtotal}</Typography>
            </Box>
            <CheckoutBillingDescriptor />
        </Box>
    );
};
