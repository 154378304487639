import gql from 'graphql-tag';

export const PaymentGatewayInfoFragmentCS = gql`
    fragment PaymentGatewayFragmentCS on PaymentGateway {
        id
        description
        title
        icon
    }
`;
