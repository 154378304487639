import React from 'react';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import {ShippingRate} from '../../core/models/CartRemote';
import {SelectorListOptionItem} from './SelectorListOptionItem';

interface Props {
    rates?: ShippingRate[];
    selectedMethod?: string;
    onChange: (method: string) => void;
}

export const ShippingSelector: React.FC<Props> = ({rates, selectedMethod, onChange}) => {
    if (!rates || rates.length === 0) {
        return <div>No Shipping Options available</div>;
    }
    const shippingItemsEl = rates.map(item => (
        <React.Fragment key={item.id}>
            <SelectorListOptionItem
                checked={selectedMethod === item.id}
                value={item.id}
                primaryText={item.label}
                secondaryText={`Cost: ${item.cost}`}
                onChange={onChange}
            />
            <Divider />
        </React.Fragment>
    ));

    return (
        <List aria-label="Shipping Options">
            <Divider />
            {shippingItemsEl}
        </List>
    );
};
