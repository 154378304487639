import React from 'react';

import Typography from '@material-ui/core/Typography';
// import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {FormalTextField} from '../../uxui/forms/FormalTextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import {BoxWithVerticalGap} from '../../uxui/BoxWithVerticalGap';
import {FormalCheckbox} from '../../uxui/forms/FormalCheckbox';

interface Props {
    fieldsProps: {
        email: any;
        subscribeToNewsletter: any;
    };
}

export const CustomerForm: React.FC<Props> = ({fieldsProps}) => {
    return (
        <BoxWithVerticalGap>
            <BoxWithVerticalGap>
                <Typography variant="h5">Contact Informaction</Typography>
            </BoxWithVerticalGap>
            <FormalTextField fullWidth label="Email" {...fieldsProps.email} />
            <BoxWithVerticalGap>
                <FormControlLabel
                    control={<FormalCheckbox {...fieldsProps.subscribeToNewsletter} />}
                    label="Keep me up to date on news and exclusive offers"
                />
                {fieldsProps && fieldsProps.subscribeToNewsletter && fieldsProps.subscribeToNewsletter.error ? (
                    <FormHelperText>{fieldsProps.subscribeToNewsletter.error}</FormHelperText>
                ) : null}
            </BoxWithVerticalGap>
        </BoxWithVerticalGap>
    );
};
