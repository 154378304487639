import {QueryHookOptions, useQuery} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {CartInfoDetailedFragmentCS} from '../../core/fragments/apollo/CartInfoDetailedFragmentCS';
import {CustomerInfoFragmentCS} from '../../core/fragments/apollo/CustomerInfoFragmentCS';
import {PaymentGatewayInfoFragmentCS} from '../../core/fragments/apollo/PaymentGatewayInfoFragmentCS';

export const FETCH_CART = gql`
    query GetCartCustomerPayments {
        cart {
            ...CartInfoDetailedFragmentCS
        }
        customer {
            ...CustomerInfoFragmentCS
        }
        paymentGateways {
            edges {
                node {
                    ...PaymentGatewayFragmentCS
                }
            }
        }
    }
    ${CartInfoDetailedFragmentCS}
    ${CustomerInfoFragmentCS}
    ${PaymentGatewayInfoFragmentCS}
`;

export function useFetchCartCustomerPayments(options?: QueryHookOptions) {
    const {loading, error, data} = useQuery(FETCH_CART, options);
    return {
        loading,
        error,
        cart: data && data.cart,
        customer: data && data.customer,
        paymentGateways: data && data.paymentGateways,
    };
}
