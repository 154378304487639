import gql from 'graphql-tag';

export const CustomerAddressInfoFragmentCS = gql`
    fragment CustomerAddressInfoFragmentCS on CustomerAddress {
        address1
        address2
        city
        company
        email
        firstName
        lastName
        phone
        postcode
        state
    }
`;
// FIXME - Fix error when retrieving country.
//         country
