import React from 'react';
import TextField, {TextFieldProps} from '@material-ui/core/TextField';

type Props = TextFieldProps & {
    error: string;
};

export const FormalTextField: React.FC<Props> = ({error, ...other}) => {
    return <TextField error={!!error} helperText={error} {...other} />;
};

FormalTextField.defaultProps = {
    variant: 'outlined',
};
