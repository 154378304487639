import React from 'react';
import Grid from '@material-ui/core/Grid';
import {FormalTextField} from '../../uxui/forms/FormalTextField';
import {styled} from '@material-ui/core/styles';
import {CheckoutSectionTitle} from './CheckoutSectionTitle';

export const GridWithVerticalGap = styled(Grid)(({theme}) => ({
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
}));

interface Props {
    title: string;
    fieldsProps: {
        firstName: any;
        lastName: any;
        address1: any;
        address2: any;
        city: any;
        country: any;
        state: any;
        zipCode: any;
    };
}

export const AddressForm: React.FC<Props> = ({fieldsProps, title}) => {
    return (
        <Grid container>
            <GridWithVerticalGap item xs={12}>
                <CheckoutSectionTitle title={title} />
            </GridWithVerticalGap>
            <Grid item xs={12}>
                <Grid container spacing={3}>
                    <GridWithVerticalGap item xs={6}>
                        <FormalTextField fullWidth label="First Name (Optional)" {...fieldsProps.firstName} />
                    </GridWithVerticalGap>
                    <GridWithVerticalGap item xs={6}>
                        <FormalTextField fullWidth label="Last Name" {...fieldsProps.lastName} />
                    </GridWithVerticalGap>
                </Grid>
            </Grid>
            <GridWithVerticalGap item xs={12}>
                <FormalTextField fullWidth label="Address" {...fieldsProps.address1} />
            </GridWithVerticalGap>
            <GridWithVerticalGap item xs={12}>
                <FormalTextField fullWidth label="Apartment, suite, etc (Optional)" {...fieldsProps.address2} />
            </GridWithVerticalGap>
            <GridWithVerticalGap item xs={12}>
                <FormalTextField fullWidth label="City" {...fieldsProps.city} />
            </GridWithVerticalGap>
            <Grid item xs={12}>
                <Grid container spacing={3}>
                    <GridWithVerticalGap item xs={4}>
                        <FormalTextField
                            fullWidth
                            disabled
                            label="Country"
                            {...fieldsProps.country}
                            helperText="We currently only Ship to USA"
                        />
                    </GridWithVerticalGap>
                    <GridWithVerticalGap item xs={4}>
                        <FormalTextField fullWidth label="State" {...fieldsProps.state} />
                    </GridWithVerticalGap>
                    <GridWithVerticalGap item xs={4}>
                        <FormalTextField fullWidth label="Zip Code" {...fieldsProps.zipCode} />
                    </GridWithVerticalGap>
                </Grid>
            </Grid>
        </Grid>
    );
};
