import React, {useEffect} from 'react';
import classNames from 'classnames';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import {CustomerForm} from '../components/CustomerForm';
import {AddressForm, GridWithVerticalGap} from '../components/AddressForm';
import {CheckoutProductList} from '../components/CheckoutProductList';
import {CheckoutSummary} from '../components/CheckoutSummary';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {Colors} from '../../../theme';
import {Cart} from '../../core/models/Cart';
import {DividerPadded} from '../../uxui/DividerPadded';
import * as yup from 'yup';
import useFormal from '@kevinwolf/formal-web';
import Button from '@material-ui/core/Button';
import withWidth, {isWidthDown} from '@material-ui/core/withWidth';
import {Breakpoint} from '@material-ui/core/styles/createBreakpoints';
import {LoadingModal} from '../../uxui/LoadingModal';
import {FormalCheckbox} from '../../uxui/forms/FormalCheckbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import {useCustomerMutationId} from '../../common/hooks/useCustomerMutationId';
// import {useFetchUpdateCustomer} from '../../common/hooks/useFetchUpdateCustomer';
// import {UpdateCustomerInput} from '../../core/models/mutations/UpdateCustomerInput';
import {CountriesEnum} from '../../core/models/mutations/CountriesEnum';
import {ShippingSelector} from '../components/ShippingSelector';
import {CheckoutSectionTitle} from '../components/CheckoutSectionTitle';
import {PaymentGatewayRemote} from '../../core/models/PaymentGatewayRemote';
import {CustomerRemote} from '../../core/models/CustomerRemote';
import {PaymentGatewaySelector} from '../components/PaymentGatewaySelector';
import {Typography} from '@material-ui/core';
import {CheckoutInput} from '../../core/models/mutations/CheckoutInput';
import {CustomerAddressRemote} from '../../core/models/CustomerAddressRemote';

// import {CheckoutInput} from '../../core/models/mutations/CheckoutInput';

enum CHECKOUT_MODE {
    SIMPLIFIED = 'SIMPLIFIED',
    DETAILED = 'DETAILED',
}

const addressSchema = yup.object().shape({
    firstName: yup.string(),
    lastName: yup
        .string()
        .min(3)
        .required(),
    address1: yup
        .string()
        .min(5)
        .required(),
    address2: yup.string(),
    city: yup.string().required(),
    // country: yup.string().required(),
    state: yup.string().required(),
    postcode: yup
        .string()
        .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, 'Zip code must be 12345 or 12345-1234')
        .min(3)
        .required(),
});

const schema = yup.object().shape({
    email: yup
        .string()
        .email()
        .required(),
    useBillingAddressAsShipping: yup.boolean(),
    subscribeNewsletter: yup.boolean(),
    saveInfo: yup.boolean(),
    shippingMethod: yup.string().required(),
    paymentGateway: yup.string().required(),
});

const addressInit = {
    firstName: '',
    lastName: '',
    address1: '',
    address2: '',
    city: '',
    // country: 'United States',
    state: '',
    postcode: '',
};

const initialValues = {
    email: '',
    useBillingAddressAsShipping: true,
    subscribeNewsletter: true,
    saveInfo: true,
    shippingMethod: '',
    paymentGateway: '',
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        contrast: {
            [theme.breakpoints.up('sm')]: {
                borderLeft: `1px solid ${Colors.GRAY}`,
            },
            backgroundColor: Colors.LIGHTER_GRAY,
        },
        section: {
            padding: theme.spacing(4),
        },
    }),
);

interface Props {
    cart?: Cart;
    customer: CustomerRemote;
    paymentGateways?: PaymentGatewayRemote[];
    mode?: CHECKOUT_MODE;
    loading: boolean;
    width: Breakpoint;
    onCheckout: (input: CheckoutInput) => void;
}

const _CheckoutPageContent: React.FC<Props> = ({cart, loading, mode, paymentGateways, width, onCheckout}) => {
    const classes = useStyles();
    const isMobile = isWidthDown('sm', width);
    const clientMutationId = useCustomerMutationId();

    // const onUpdateCustomerComplete = (_data: any) => {
    //     console.log('onUpdateCustomerComplete...');
    //     // eslint-disable-next-line @typescript-eslint/no-use-before-define
    // };

    // const updateCustomerContext = useFetchUpdateCustomer({onCompleted: onUpdateCustomerComplete});
    // const {fetchUpdateCustomer} = updateCustomerContext;

    const shippingRates =
        cart && cart.availableShippingMethods && cart.availableShippingMethods.length > 0
            ? cart.availableShippingMethods[0].rates
            : undefined;
    // console.log('checkout.shippingRates:', shippingRates, cart);

    // const updateCustomer = (values: any, defaultAddress: CustomerAddressRemote) => {
    //     console.log('updateCustomer:', values);
    //     const input: UpdateCustomerInput = {
    //         clientMutationId,
    //         email: values.email,
    //         firstName: defaultAddress.firstName,
    //         lastName: defaultAddress.lastName,
    //         shipping: values.shipping,
    //         billing: values.billing,
    //     };
    //     fetchUpdateCustomer({variables: {input}});
    // };

    const doCheckout = (values: any, _defaultAddress: CustomerAddressRemote) => {
        const selectedGateway = paymentGateways && paymentGateways.find(item => (item.id = values.paymentGateway));
        const input: CheckoutInput = {
            // account: {
            //     username: values.email,
            //     password: 'testpass',
            // },
            clientMutationId,
            shipToDifferentAddress: !values.useBillingAddressAsShipping,
            shipping: values.shipping,
            billing: values.billing,
            shippingMethod: values.shippingMethod,
            paymentMethod: values.paymentGateway,
            paymentMethodTitle: selectedGateway ? selectedGateway.title : '',
        };
        console.log('checkoutInput:', input);
        try {
            onCheckout(input);
        } catch (_err) {}
    };

    const handleSubmit = async (values: any) => {
        const defaultAddress = mode === CHECKOUT_MODE.SIMPLIFIED ? values.shipping : values.billing;
        const shipping =
            mode === CHECKOUT_MODE.SIMPLIFIED
                ? values.shipping
                : values.useBillingAddressAsShipping
                ? values.billing
                : values.shipping;
        const billing = mode === CHECKOUT_MODE.SIMPLIFIED ? values.shipping : values.shipping;
        shipping.country = CountriesEnum.UNITED_STATES_US;
        billing.country = CountriesEnum.UNITED_STATES_US;
        const finalValues = {...values, ...{billing, shipping}};
        console.log('finalValues:', finalValues);
        // await updateCustomer(finalValues, defaultAddress);
        await doCheckout(finalValues, defaultAddress);
    };

    const isLoading = loading;

    const formal = useFormal(initialValues, {
        schema,
        onSubmit: () => {},
    });

    const formalBillingAddress = useFormal(addressInit, {
        schema: addressSchema,
        onSubmit: () => {},
    });
    const formalShippingAddress = useFormal(addressInit, {
        schema: addressSchema,
        onSubmit: () => {},
    });

    // useEffect(() => {
    //     if (!customer || !customer.billing || !customer.shipping) return;
    // }, [customer]);
    useEffect(() => {
        if (!cart) return;
        formal.change('shippingMethod', cart.chosenShippingMethod);
    }, [cart && cart.chosenShippingMethod]);

    useEffect(() => {
        if (!paymentGateways || paymentGateways.length === 0 || formal.values.paymentGateway) return;
        const gateway: PaymentGatewayRemote = paymentGateways[0];
        if (!gateway) return;
        formal.change('paymentGateway', gateway.id);
    }, [paymentGateways]);

    const handleSelectedShippingChanged = (method: string) => {
        formal.change('shippingMethod', method);
    };

    const handlePaymentMethodChanged = (paymentGateway: string) => {
        formal.change('paymentGateway', paymentGateway);
    };

    const onPreSubmit = async () => {
        try {
            console.log('formal:', formal.values, paymentGateways);
            await formal.validate();
            // @ts-ignore
            if (!mode === CHECKOUT_MODE.SIMPLIFIED) {
                await formalBillingAddress.validate();
            }
            await formalShippingAddress.validate();
            const formValues = {
                ...formal.values,
                billing: {...formalBillingAddress.values},
                shipping: {...formalShippingAddress.values},
            };
            handleSubmit(formValues);
        } catch (err) {
            console.log('error:', err);
        }
    };

    const billindAddressBlock = (
        <AddressForm
            title="Billing Address"
            fieldsProps={{
                firstName: formalBillingAddress.getFieldProps('firstName') as any,
                lastName: formalBillingAddress.getFieldProps('lastName') as any,
                address1: formalBillingAddress.getFieldProps('address1') as any,
                address2: formalBillingAddress.getFieldProps('address2') as any,
                city: formalBillingAddress.getFieldProps('city') as any,
                state: formalBillingAddress.getFieldProps('state') as any,
                country: {value: 'United States (USA)'},
                zipCode: formalBillingAddress.getFieldProps('postcode') as any,
            }}
        />
    );

    const shippingAddressBlock = (
        <AddressForm
            title="Shipping Address"
            fieldsProps={{
                firstName: formalShippingAddress.getFieldProps('firstName' as any) as any,
                lastName: formalShippingAddress.getFieldProps('lastName' as any) as any,
                address1: formalShippingAddress.getFieldProps('address1' as any) as any,
                address2: formalShippingAddress.getFieldProps('address2' as any) as any,
                city: formalShippingAddress.getFieldProps('city' as any) as any,
                state: formalShippingAddress.getFieldProps('state' as any) as any,
                country: {value: 'United States (USA)'},
                zipCode: formalShippingAddress.getFieldProps('postcode' as any) as any,
            }}
        />
    );

    const renderAddressForms = () => {
        if (mode === CHECKOUT_MODE.SIMPLIFIED) {
            return <>{shippingAddressBlock}</>;
        }
        return (
            <>
                {billindAddressBlock}
                <FormControlLabel
                    control={<FormalCheckbox {...formal.getFieldProps('useBillingAddressAsShipping')} />}
                    label="Use same address for shipping"
                />
                {!formal.values.useBillingAddressAsShipping && shippingAddressBlock}
            </>
        );
    };

    const shippingSection = (
        <GridWithVerticalGap>
            <CheckoutSectionTitle title="Shipping Method" />
            <ShippingSelector
                rates={shippingRates}
                selectedMethod={formal.values.shippingMethod}
                onChange={handleSelectedShippingChanged}
            />
            {formal.getFieldProps('shippingMethod').error && (
                <Typography variant="body2" color="error">
                    {formal.getFieldProps('shippingMethod').error}
                </Typography>
            )}
        </GridWithVerticalGap>
    );

    const paymentGatewaysSection = (
        <GridWithVerticalGap>
            <CheckoutSectionTitle title="Payment Method" />
            <PaymentGatewaySelector
                paymentGateways={paymentGateways}
                selectedPaymentGateway={formal.values.paymentGateway}
                onChange={handlePaymentMethodChanged}
            />
            {formal.getFieldProps('paymentGateway').error && (
                <Typography variant="body2" color="error">
                    {formal.getFieldProps('paymentGateway').error}
                </Typography>
            )}
        </GridWithVerticalGap>
    );

    const formSection = (
        <Grid className={classes.section} item xs={12} md={6}>
            <CustomerForm
                fieldsProps={{
                    email: formal.getFieldProps('email') as any,
                    subscribeToNewsletter: formal.getFieldProps('subscribeNewsletter'),
                }}
            />
            {renderAddressForms()}
            <GridWithVerticalGap item xs={12}>
                <FormControlLabel
                    control={<FormalCheckbox {...formal.getFieldProps('saveInfo')} />}
                    label="Save this information for next time"
                />
                {formal.getFieldProps('saveInfo') && formal.getFieldProps('saveInfo').error ? (
                    <FormHelperText>{formal.getFieldProps('saveInfo').error}</FormHelperText>
                ) : null}
            </GridWithVerticalGap>
            {shippingSection}
            {paymentGatewaysSection}
            <Grid container>
                <Grid item xs={12} className={classes.actions}>
                    <Button variant={'contained'} color={'primary'} onClick={onPreSubmit}>
                        Continue to Checkout
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );

    const productSummarySection = (
        <Grid className={classNames(classes.section, classes.contrast)} item xs={12} md={6}>
            {cart && <CheckoutProductList cart={cart} />}
            <DividerPadded variant="fullWidth" />
            {cart && <CheckoutSummary cart={cart} />}
        </Grid>
    );

    return (
        <Container>
            <form {...formal.getFormProps()}>
                <Grid container>
                    {isMobile ? productSummarySection : formSection}
                    {isMobile ? formSection : productSummarySection}
                </Grid>
            </form>
            <LoadingModal open={isLoading} />
        </Container>
    );
};

_CheckoutPageContent.defaultProps = {
    mode: CHECKOUT_MODE.SIMPLIFIED,
};

export const CheckoutPageContent = withWidth()(_CheckoutPageContent);
