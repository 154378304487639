import {MutationOptions, useMutation} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {CheckoutPayloadInfoFragmentCS} from '../../core/fragments/apollo/CheckoutPayloadInfoFragmentCS';

const CHECKOUT = gql`
    mutation Checkout($input: CheckoutInput!) {
        checkout(input: $input) {
            clientMutationId
            ...CheckoutPayloadInfoFragmentCS
        }
    }
    ${CheckoutPayloadInfoFragmentCS}
`;

export function useFetchCheckout(options?: Partial<MutationOptions>) {
    const [checkout, {loading, data}] = useMutation(CHECKOUT, options);
    return {checkout, checkoutLoading: loading, checkoutData: data};
}
