import gql from 'graphql-tag';
import {CustomerAddressInfoFragmentCS} from './CustomerAddressInfoFragmentCS';

export const CustomerInfoFragmentCS = gql`
    fragment CustomerInfoFragmentCS on Customer {
        billing {
            ...CustomerAddressInfoFragmentCS
        }
        calculatedShipping
        customerId
        date
        displayName
        email
        firstName
        hasCalculatedShipping
        id
        isPayingCustomer
        isVatExempt
        lastName
        orderCount
        role
        shipping {
            ...CustomerAddressInfoFragmentCS
        }
        totalSpent
        username
    }
    ${CustomerAddressInfoFragmentCS}
`;
