import gql from 'graphql-tag';
import {OrderCoreInfoFragmentCS} from './OrderCoreInfoFragmentCS';

export const CheckoutPayloadInfoFragmentCS = gql`
    fragment CheckoutPayloadInfoFragmentCS on CheckoutPayload {
        order {
            ...OrderCoreInfoFragmentCS
        }
        redirect
        result
    }
    ${OrderCoreInfoFragmentCS}
`;
