import React from 'react';
import {Cart} from '../../core/models/Cart';
import {CheckoutProductItem} from './CheckoutProductItem';
import Box from '@material-ui/core/Box';

interface Props {
    cart: Cart;
}

export const CheckoutProductList: React.FC<Props> = ({cart}) => {
    const itemsEl =
        cart && cart.cartItems ? cart.cartItems.map(item => <CheckoutProductItem key={item.key} item={item} />) : null;
    return <Box>{itemsEl}</Box>;
};
