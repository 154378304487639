import React from 'react';
import Typography from '@material-ui/core/Typography';

interface Props {
    className?: string;
}

export const CheckoutBillingDescriptor: React.FC<Props> = ({className}) => {
    return (
        <Typography className={className} color="error" variant="body2">
            Charges on your statement will appear as WEWILLPROVIDExxxxxxxxxx
        </Typography>
    );
};
