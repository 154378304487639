import React from 'react';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import {PaymentGatewayRemote} from '../../core/models/PaymentGatewayRemote';
import {SelectorListOptionItem} from './SelectorListOptionItem';

interface Props {
    paymentGateways?: PaymentGatewayRemote[];
    selectedPaymentGateway?: string;
    onChange: (method: string) => void;
}

export const PaymentGatewaySelector: React.FC<Props> = ({paymentGateways, selectedPaymentGateway, onChange}) => {
    if (!paymentGateways || paymentGateways.length === 0) {
        return <div>No Payment Methods available</div>;
    }
    const paymentItemsEl = paymentGateways.map(item => (
        <React.Fragment key={item.id}>
            <SelectorListOptionItem
                checked={selectedPaymentGateway === item.id}
                value={item.id}
                primaryText={item.title}
                secondaryText={item.description}
                onChange={onChange}
            />
            <Divider />
        </React.Fragment>
    ));

    return (
        <List aria-label="Shipping Options">
            <Divider />
            {paymentItemsEl}
        </List>
    );
};
