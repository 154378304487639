import {graphql, useStaticQuery} from 'gatsby';
import _get from 'lodash/get';
import {mapEdgeNodeListToProduct} from '../../core/mapper/ProductMapper';
import {ProductCore} from '../../core/models/ProductCore';

export function useProductCatalogThumbS(): ProductCore[] {
    const staticData = useStaticQuery(graphql`
        query ProdCatalogS {
            wpgraphql {
                products(where: {status: "publish"}) {
                    edges {
                        node {
                            ...ProductInfoFragment
                            ...ProductAttrsFragment
                            ...ProductImageThumbSFragment
                        }
                    }
                }
            }
        }
    `);
    const productNodes = _get(staticData, 'wpgraphql.products.edges');
    return mapEdgeNodeListToProduct(productNodes);
}
