import {CartRemote} from '../models/CartRemote';
import {Cart, CartItem} from '../models/Cart';
import {mapNodeToProductCore} from './ProductMapper';
import {mapProductVariationNode} from './ProductVariationMapper';
import {ProductSpecific} from '../models/ProductSpecific';
import {computeProductSpecificId} from '../util/ProductSpecificUtil';
import {computeCartItemCount} from '../util/CartUtil';
import {ProductCore} from '../models/ProductCore';

export function mapCartRemoteToCart(id: any, cartRemote: CartRemote): Cart {
    if (!cartRemote) return {id} as any;
    const cartItems: CartItem[] = cartRemote.contents.edges.map(({node}) => {
        const product = mapNodeToProductCore(node.product);
        const prodVariation = node.variation ? mapProductVariationNode(node.variation) : undefined;
        const prodSpecificId = computeProductSpecificId(product.id, prodVariation && prodVariation.id);
        const productSpecific: ProductSpecific = {id: prodSpecificId, product, selectedVariation: prodVariation};
        return {
            ...node,
            ...{product, productSpecific, variation: prodVariation},
        };
    });
    const tmpCart: Cart = {
        id,
        itemCount: 0,
        ...cartRemote,
        ...{cartItems},
    };
    tmpCart.itemCount = computeCartItemCount(tmpCart);
    return tmpCart;
}

export function mapCartRemoteToCartWithImages(
    id: any,
    cartRemote: CartRemote,
    productCatalog: ProductCore[],
): Cart | undefined {
    if (!cartRemote || !productCatalog) return undefined;
    const mappedCart = mapCartRemoteToCart(id, cartRemote);
    if (!mappedCart.cartItems) return mappedCart;
    mappedCart.cartItems.forEach(item => {
        const productWithImage = productCatalog.find(other => other.id === item.product.id);
        if (!productWithImage) return;
        item.product.mainImage = productWithImage.mainImage;
    });
    return mappedCart;
}
